<i18n>
{
  "en": {
    "payments": "Payments",
    "payments-subtitle": "Manage payment options and see payment history.",
    "payment-portal": "Payment portal",
    "loading-payment-status": "Loading payment status…",
    "loading-payment-status-error": "Error loading payment status",
    "payment-status-inactive": "No valid payment method set up!",
    "payment-status-active": "Active payment method is valid"
  },
  "ja": {
    "payments": "お支払い",
    "payments-subtitle": "クレジットカードの登録とお支払い履歴を確認できます。",
    "payment-portal": "お支払いポータルを開く",
    "loading-payment-status": "支払い状況読み込み中…",
    "loading-payment-status-error": "支払い状況の読み込みエラー",
    "payment-status-inactive": "有効な支払い方法が設定されていません！",
    "payment-status-active": "有効な支払い方法が設定されています"
  }
}
</i18n>

<template>
  <v-card elevation="4">
    <v-card-title>{{ $t('payments') }}</v-card-title>
    <v-card-subtitle>{{ $t('payments-subtitle') }}</v-card-subtitle>
    <v-card-text>
      <p v-if="!paymentStatus">
        <v-progress-circular indeterminate width="2" size="12"></v-progress-circular>
        {{ $t('loading-payment-status') }}
      </p>
      <p v-else-if="paymentStatus === 'error'">
        <v-icon color="error">mdi-alert-octagon</v-icon>
        {{ $t('loading-payment-status-error') }}
      </p>
      <p v-else-if="paymentStatus === 'inactive'">
        <v-icon color="warning">mdi-credit-card</v-icon>
        {{ $t('payment-status-inactive') }}
      </p>
      <p v-else-if="paymentStatus === 'active'">
        <v-icon color="success">mdi-check-decagram</v-icon>
        {{ $t('payment-status-active') }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-form method="POST" :action="paymentPortalUri" @submit.passive="working = true">
        <input type="hidden" name="next" :value="portalReturnUri">
        <v-btn text
               type="submit"
               color="primary"
               :disabled="working"
               :loading="working">
          {{ $t('payment-portal') }}
          <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PaymentWidget',
  data: () => ({
    working: false,
    paymentStatus: undefined
  }),
  async mounted() {
    /** @type {Response} */
    const response = await this.$api.fetch('/payments/status');

    if (!response.ok) {
      this.paymentStatus = 'error';
    } else {
      const result = await response.json();
      this.paymentStatus = result.status;
    }
  },
  computed: {
    paymentPortalUri() {
      return `${this.$api.base}/payments/portal?jwt=${this.$api.token}`;
    },
    portalReturnUri() {
      return window.location;
    }
  }
}
</script>